<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'待确认的异常'" />
        </div>

        <div class="commonControl-body">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="error_code" label="错误码">
                </el-table-column>

                <el-table-column prop="abnorma.error_info" label="错误信息">
                </el-table-column>

                <el-table-column prop="abnorma.error_level" label="错误等级">
                </el-table-column>

                <el-table-column prop="module.module_name" label="错误模块">
                </el-table-column>

                <el-table-column
                    prop="product.product_name"
                    label="错误码关联系统"
                >
                </el-table-column>

                <el-table-column
                    prop="product.product_type"
                    label="发生错误的系统类型"
                    
                >
                </el-table-column>

                <el-table-column label="是否需要生成工单">
                    <template slot-scope="scope">
                        <template v-if="scope.row.abnorma.manual == false"
                            >否</template
                        >
                        <template v-else-if="scope.row.abnorma.manual == true"
                            >是(请尽快确认派发工单或回退)</template
                        >
                        <template v-else>异常</template>
                    </template>
                </el-table-column>

                <el-table-column label="上报类型">
                    <template slot-scope="scope">
                        <template v-if="scope.row.report_type == 1"
                            >调度异常</template
                        >
                        <template v-else-if="scope.row.report_type == 2"
                            >设备异常</template
                        >
                        <template v-else>异常</template>
                    </template>
                </el-table-column>

                <el-table-column label="错误对象">
                    <template slot-scope="scope">
                        <template v-if="scope.row.report_type == 1">
                            <span>{{ scope.row.garage.info_name }}</span>
                        </template>
                        <template v-else-if="scope.row.report_type == 2">
                            <span>{{ scope.row.equipment.equipment_id }}</span>
                        </template>
                        <template v-else>异常</template>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="processing_status"
                    label="异常状态"
                    width="80"
                ></el-table-column>
                <el-table-column
                    label="操作"
                    align="center"
                    fixed="right"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-button @click="toInfo(scope.row.id)"
                            >查看异常详情</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="layoutBorder">
            <Pagination
                :total="query.total"
                :page.sync="query.currentPage"
                @pagination="getList()"
                :limit.sync="query.pagesize"
            />
        </div>
    </div>
</template>

<script>
import { query } from '@/api/manage/operation/error_report_manage/all.js';
import Pagination from '@/components/Pagination.vue';

export default {
    name: 'errorReportManage',
    data() {
        return {
            query: {
                currentPage: 1, //获取页码
                pagesize: 10, //每页返回数量
                total: 0
            },
            tableData: []
        };
    },
    components: {
        Pagination
    },
    created() {
        this.getList();
    },
    methods: {
        getList() {
            this.$http(
                query({
                    querypage: this.query.currentPage,
                    processing_status: 1
                }),
                res => {
                    console.log(res);
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.tableData = res.data.result;
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            duration: 5 * 1000
                        });
                    }
                }
            );
        },
        toInfo(id) {
            this.$router.push({
                name: 'error_report_detail',
                query: { errorID: id }
            });
        }
    }
};
</script>

<style lang="scss" scoped></style>
